import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/aftersales/produk-existing',
        component: () => import('@/layout/ProductExistingLayout.vue'),
        redirect: '/aftersales/produk-existing/aktif',
        children: [
            {
                path: 'aktif',
                name: 'ActiveProductExistingView',
                component: () => import('@/views/ActiveProductExistingView.vue'),
            },
            {
                path: 'expired',
                name: 'ExpiredProductExisting',
                component: () => import('@/views/ExpiredProductExisting.vue'),
            },
            {
                path: 'detail',
                name: 'DetailBillingFormView',
                component: () => import('@/views/DetailBillingFormView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
